<template>
  <v-row class="quotation-preview">
    <v-col cols="12" md="10">
      <v-card id="printArea">
        <v-card-text style="padding: 32px 36px;line-height: 1.3rem;">
          <v-row>
            <v-col cols="12" style="font-weight: 500;padding-bottom: 0;margin-bottom: 0;">
              <div style="margin-top: 30px;text-align: center;">
                <span style="color: #rgba(0, 0, 0, 0.87); font-weight: 700; font-size: 1.7rem; line-height:1.6rem;padding-left: 12px;">
                  PROFORMA INVOICE
                </span>
              </div>
              <div style="width: 100%;display: flex;justify-content: end;">
                <div style="display: flex;flex-direction: column-reverse;">
                  <div>
                    <!-- {{ $t('Invoice.Date') }} : -->
                    No.:
                  </div>
                  <div>
                    <!-- {{ $t('Invoice.Code') }} : -->
                    Date :
                  </div>
                </div>
                <div style="display: flex;flex-direction: column-reverse;text-align: end;width: 100px;">
                  <div>
                    {{ contract.cont_date }}
                  </div>
                  <div>
                    {{ contract.cont_no }}
                  </div>
                </div>
              </div>
              <div style="border:1px solid #000; margin: 10px 0 10px 0;padding-bottom: 0;"></div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="7" style="padding-top: 0px;">
              <table>
                <!-- <v-row>
                  <v-col cols="3" class="margin-right:auto">
                    Sold To:
                  </v-col>
                  <v-col cols="9" class="margin-left:auto">
                    {{ customer.cust_name }}
                  </v-col>
                </v-row> -->
                <!-- <th>Sold To:</th>
                <td><span>{{ customer.cust_name }}</span></td> -->
                <tr>
                  <td width="90" style="font-weight: 700;vertical-align: top">
                    Sold To:
                  </td>
                  <td width="600">
                    {{ customer.cust_name_eng }}
                  </td>
                </tr>
                <tr>
                  <td width="90" style="font-weight: 700;vertical-align: top">
                    Address:
                  </td>
                  <td width="600">
                    {{ customer.address2_eng }}
                  </td>
                </tr>
                <tr>
                  <td width="90">
                  </td>
                  <td width="600">
                    {{ customer.address1_eng }}
                  </td>
                </tr>
                <tr>
                  <td width="90" style="font-weight: 700;vertical-align: top">
                    Tax No.:
                  </td>
                  <td width="600">
                    {{ customer.cust_tax_no }}
                  </td>
                </tr>
                <tr>
                  <td width="90" style="font-weight: 700;vertical-align: top">
                    Tel:
                  </td>
                  <td width="600">
                    {{ customer.tel }}
                  </td>
                </tr>
                <tr>
                  <td width="90" style="font-weight: 700;vertical-align: top">
                    E-mail:
                  </td>
                  <td width="600">
                    {{ customer.email }}
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col cols="5" style="flex-direction: column;padding-left: 0;padding-bottom: 0;">
              <v-row align="center">
                <v-col cols="12" style="display: flex;justify-content:end; text-align:center;padding-bottom: 0;align-items: center;">
                  <img src="@/assets/images/logos/favicon.png" height="40px" style="object-fit: contain;margin: 0 0 0 0;" />
                  <div style="font-size:1.0rem;padding-left: 24px;font-weight: 800;">
                    <div>株式会社グリーン・アース</div>
                    <div>GREEN EARTH CO., LTD.</div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" style="padding-top: 0;padding-left: 0;">
                  <div style="display: flex;justify-content:end;font-size:0.8rem;padding-left: 24px;font-weight: 800;">
                    1679-1 Nekozane, Bandou City,
                  </div>
                  <div style="display: flex;justify-content:end;font-size:0.8rem;padding-left: 24px;font-weight: 800;">
                    <!-- <span style="max-width: 250px; text-align: end;"> -->
                    Ibaraki Prefecture 306-0616, JAPAN
                    <!-- </span> -->
                  </div>

                  <div style="display: flex;justify-content:end;font-size:0.8rem;padding-left: 24px;font-weight: 800;">
                    TEL: 0297-44-4338
                  </div>
                  <div style="display: flex;justify-content:end;font-size:0.8rem;padding-left: 24px;font-weight: 800;">
                    FAX: 0297-44-4335
                  </div>
                  <div style="display: flex;justify-content:end;font-size:0.8rem;padding-left: 24px;font-weight: 800;">
                    代表取締役: 柳川 智惠
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <table>
                <tr>
                  <td width="90" style="font-weight: 700;vertical-align: top;">
                    <div>ATTN:</div>
                  </td>
                  <td width="1000">
                    <div class="under-line">
                      &nbsp;{{ contract.cont_attn }}
                    </div>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
          <!-- <v-row></v-row> -->
          <!-- <div style="border:1px solid #000; margin: 10px 0 10px 0;padding-bottom: 0;"></div>
          <div style="padding-left: 12px;padding-top: 12px;">
            {{ $t('Invoice.Payment') }} :
          </div> -->
          <v-row>
            <v-col cols="12">
              <!-- <table class="purchasedItems">
                <thead class="thead">
                  <tr>
                    <th style="text-align: center;width:5%">
                      No.
                    </th>
                    <th style="text-align: left;width:21%">
                      Container Info.
                    </th>

                    <th style="text-align:left;width:30%;">
                      Description of goods
                    </th>
                    <th style="text-align:right;width:5%;">
                      FCB(F)
                    </th>
                    <th style="text-align:right;width:5%;">
                      Price({{ contract.ccy_code }})
                    </th>
                    <th style="text-align:right;width:9%;">
                      QTY.(KG)
                    </th>
                    <th style="text-align: right;width:10%;">
                      Amount({{ contract.ccy_code }})
                    </th>
                  </tr>
                </thead>
                <tbody class="tbody">
                  <tr v-for="(item, no) in contract.containers" :key="no">
                    <td style="text-align: center;">
                      <div>{{ item.no }}</div>
                    </td>
                    <td style="text-align: left;vertical-align: top">
                      <div>ContaierNo: {{ item.cntr_no }}</div>
                      <div>Seal No.: {{ item.seal_no }}</div>
                      <div>B/L No.: {{ item.cntr_bl }}</div>
                    </td>

                    <td style="text-align: left;vertical-align: top">
                      <div v-for="(detail,cntr_dtl_id) in item.details" :key="cntr_dtl_id">
                        {{ detail.product_name }}
                      </div>
                    </td>
                    <td style="text-align: right;vertical-align: top">
                      <div v-for="(detail,cntr_dtl_id) in item.details" :key="cntr_dtl_id">
                        {{ detail.cntr_dtl_fcb }}F
                      </div>
                    </td>
                    <td style="text-align: right;vertical-align: top">
                      <div v-for="(detail,cntr_dtl_id) in item.details" :key="cntr_dtl_id">
                        {{ contract.ccy_mark }}{{ formatData(detail.cntr_dtl_price) }}
                      </div>
                    </td>
                    <td style="text-align: right;vertical-align: top">
                      <div v-for="(detail,cntr_dtl_id) in item.details" :key="cntr_dtl_id">
                        {{ formatData(detail.cntr_dtl_weight) }}
                      </div>
                    </td>
                    <td style="text-align: right;vertical-align: top">
                      <div v-for="(detail,cntr_dtl_id) in item.details" :key="cntr_dtl_id">
                        {{ formatAmount(contract.ccy_mark, detail.cntr_dtl_amount) }}
                      </div>
                    </td>
                  </tr>
                  <tr style="font-weight: bold;">
                    <td style="text-align: center;" colspan="2">
                      TOTAL
                    </td>
                    <td>
                      {{ contract.ic_code }} {{ contract.dest_name }}
                    </td>
                    <td style="text-align: right;">
                      {{ SumFCB }}F
                    </td>
                    <td></td>
                    <td style="text-align: right;">
                      {{ SumQTY }}
                    </td>
                    <td style="text-align: right;">
                      {{ SumAmount(contract.ccy_mark) }}
                    </td>
                  </tr>
                </tbody>
                <tfoot class="tfoot">
                  <tr>
                    <th style="text-align: left;width:60%">
                      {{ $t('Invoice.Total') }}
                    </th>

                    <th style="text-align: right;width:10%;font-size:0.85rem;">
                      4,900
                    </th>
                    <th style="text-align:right;width:10%;">
                      {{ $t('Invoice.CostAndFreight') }}
                    </th>
                    <th style="text-align: right;width:20%;font-size:0.85rem;">
                      2,209,000
                    </th>
                  </tr>
                </tfoot>
              </table> -->

              <table class="purchasedItems">
                <thead class="thead">
                  <tr>
                    <th style="text-align: center;width:5%">
                      No.
                    </th>
                    <th style="text-align: left;width:24%">
                      Container Info.
                    </th>

                    <th style="text-align:left;width:30%;">
                      Description of goods
                    </th>
                    <th style="text-align:right;width:6%;">
                      FCB(F)
                    </th>
                    <th style="text-align:right;width:12%;">
                      Price({{ contract.ccy_code }})
                    </th>
                    <th style="text-align:right;width:12%;">
                      QTY.(KG)
                    </th>
                    <th style="text-align: right;width:21%;">
                      Amount({{ contract.ccy_code }})
                    </th>
                  </tr>
                </thead>
                <tbody v-for="(item, no) in contract.containers" :key="no" class="tbody">
                  <tr v-for="(detail, index) in item.details" :key="index" style="border:none">
                    <td v-if="index === 0" style="text-align: center;width:5%;" :rowspan="item.details.length">
                      <div>{{ item.no }}</div>
                    </td>
                    <td v-if="index === 0" style="text-align: left;vertical-align: top;width:24%;" :rowspan="item.details.length">
                      <div>ContaierNo: {{ item.cntr_no }}</div>
                      <div>Seal No.: {{ item.seal_no }}</div>
                      <div>B/L No.: {{ item.cntr_bl }}</div>
                    </td>

                    <td style="text-align: left;vertical-align: top;width:30%;">
                      <div>
                        {{ detail.product_name }}
                      </div>
                    </td>
                    <td style="text-align: right;vertical-align: middle;width:6%;">
                      <div>
                        {{ detail.cntr_dtl_fcb }}F
                      </div>
                    </td>
                    <td style="text-align: right;vertical-align: middle;width:12%;">
                      <div>
                        {{ formatPrice(contract.ccy_mark, detail.cntr_dtl_price) }}
                      </div>
                    </td>
                    <td style="text-align: right;vertical-align: middle;width:12%;">
                      <div>
                        {{ formatData(detail.cntr_dtl_weight) }}
                      </div>
                    </td>
                    <td style="text-align: right;vertical-align: middle;width:21%;">
                      <div>
                        {{ formatAmount(contract.ccy_mark, detail.cntr_dtl_amount) }}
                      </div>
                    </td>
                  </tr>
                  <tr v-if="no === contract.containers.length-1" style="font-weight: bold;">
                    <td style="text-align: center;" colspan="2">
                      TOTAL
                    </td>
                    <td>
                      {{ contract.ic_code }} {{ contract.dest_name }}
                    </td>
                    <td style="text-align: right;">
                      {{ SumFCB }}F
                    </td>
                    <td></td>
                    <td style="text-align: right;">
                      {{ SumQTY }}
                    </td>
                    <td style="text-align: right;">
                      {{ SumAmount(contract.ccy_mark) }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-row>
          <!-- <div class="div-boeder">
            <v-row>
              <v-col cols="4">
                国家名(Country):
              </v-col>
              <v-col cols="8">
                JAPAN
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                銀行名(Bank Name):
              </v-col>
              <v-col cols="4">
                The JoyoBank, Ltd. Iwai Branch
              </v-col>
            </v-row>
          </div> -->
        </v-card-text>
        <v-card style="padding: 32px 36px;position:absolute;bottom:0;line-height: 1.5rem;" flat>
          <div>
            <table class="table-bank">
              <tr>
                <td width="400" class="padding-left: 100px;">
                  国家名(Country Name):
                </td>
                <td width="1000">
                  JAPAN
                </td>
              </tr>
              <tr>
                <td width="400" class="padding-left: 100px;">
                  银行名(Bank Name):
                </td>
                <td width="1000">
                  The Joyo Bank, Ltd. Iwai Branch
                </td>
              </tr>
              <tr>
                <td width="400" class="padding-left: 100px;">
                  银行代码(SWIFT CODE):
                </td>
                <td width="1000">
                  JOYO JP JT
                </td>
              </tr>
              <tr>
                <td width="400" class="padding-left: 100px;" style="text-align: left;vertical-align: top">
                  银行地址(Bank Address):
                </td>
                <td width="1000">
                  3289-1, Iwai, Bando City, Ibaraki Prefecture, 306-0631, Japan
                </td>
              </tr>
              <tr>
                <td width="400" class="padding-left: 100px;">
                  账户号码(Account No.):
                </td>
                <td width="1000">
                  085-0028738
                </td>
              </tr>
              <tr>
                <td width="400" class="padding-left: 100px;">
                  账户名(Account Name):
                </td>
                <td width="1000">
                  GREEN EARTH CO., LTD. （株式会社グリーン・アース）
                </td>
              </tr>
              <tr>
                <td width="400" class="padding-left: 100px;" style="text-align: left;vertical-align: top">
                  地址(Address):
                </td>
                <td width="1000">
                  1679-1, Nekonzane, Bando City, Ibaraki Prefecture,306-0616, JAPAN
                </td>
              </tr>
              <tr>
                <td width="400" class="padding-left: 100px;">
                  电话(TEL):
                </td>
                <td width="1000">
                  (+81)297-44-4335, (+81)90-5328-5051
                </td>
              </tr>
            </table>
          </div>
        </v-card>
      </v-card>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col cols="12" md="2">
      <v-card class="mb-6">
        <v-card-text>
          <div class="d-flex justify-end">
            <Language>
            </Language>
          </div>

          <v-btn color="primary" class="mb-3" block @click="printContract">
            Print
          </v-btn>
          <v-btn color="secondary" block outlined :to="{ name: 'invoice-list' }">
            OK
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiSendOutline,
  mdiPlus,
  mdiPencil,
  mdiMinusCircle,
} from '@mdi/js'
import {
  mapActions, mapState, mapMutations,
} from 'vuex'
import {
  reactive,
} from '@vue/composition-api'
import printHtml from '@/utils/print'
import Language from '@/components/language.vue'

export default {
  components: {

    Language,
  },

  setup() {
    const invoiceForm = reactive({
      id: 5037,
      issuedDate: '',
      service: '',
      total: '',
      avatar: '',
      invoiceStatus: '',
      balance: '',
      dueDate: '',
      client: {
      },
    })

    const printContract = () => {
      const printArea = document.getElementById('printArea').innerHTML
      printHtml(printArea)
    }

    const showContract = lang => {
      console.log('lang:', lang)
    }

    return {
      invoiceForm,

      // Icons
      icons: {
        mdiSendOutline,
        mdiPlus,
        mdiPencil,
        mdiMinusCircle,

      },

      printContract,
      showContract,
    }
  },
  data: () => ({
    languageOvv: '',
    Selectedlanguage: '',
    customer: {
      cust_name: 'DONGGUAN sdfasas ad fasdf a HEHUI TRADING CO., Ltd.',
      address3: '',
      address2: 'No.243, Second Street SUTONG ROAD',
      address1: 'CHANGPNG TOWWN, DONGGUAN, GUANGDONG, CHINA',
      tax_no: '1234567888-334545-STXTS-33',
      tel: '(+86)1333-4444-5555,',
      email: 'david.liu@123456.com',
    },

    contract: {
      cont_id: '',
      cont_no: '',
      cont_date: '',
      ccy_mark: '',
      containers: [],
      documents: [],

    },
  }),
  computed: {
    ...mapState('contractStore', ['selectedContract']),
    ...mapState('customerStore', ['selectedCustomer']),

    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
    formatPrice() {
      return function (ccyMark, amount) {
        if (typeof amount === 'number') {
          return ccyMark + amount.toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        if (typeof amount === 'string') {
          return ccyMark + parseFloat(amount).toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }

        return ''
      }
    },

    formatAmount() {
      return function (ccyMark, amount) {
        // if (ccyMark === undefined || ccyMark === null) return ''
        // if (amount === undefined || amount === null) return ''

        // return ccyMark + amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        if (typeof amount === 'number') {
          return `${ccyMark ?? ''}${amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        }
        if (typeof amount === 'string') {
          return `${ccyMark ?? ''}${parseFloat(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
        }

        return ''
      }
    },

    SumFCB() {
      let sumFCB = 0
      this.contract.containers.forEach(c => {
        c.details.forEach(d => {
          sumFCB += d.cntr_dtl_fcb
        })
      })

      return sumFCB.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
    },
    SumQTY() {
      let sumQty = 0
      this.contract.containers.forEach(c => {
        c.details.forEach(d => {
          sumQty += d.cntr_dtl_weight
        })
      })

      return sumQty.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
    },
    SumAmount() {
      return function (ccyMark) {
        let sumAmount = 0
        this.contract.containers.forEach(c => {
          c.details.forEach(d => {
            sumAmount += d.cntr_dtl_amount
          })
        })

        return `${ccyMark}${sumAmount.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')}`
      }
    },
    onlyJa() {
      return function () {
        if (localStorage.getItem('lang') === 'ja') {
          return true
        }

        return false
      }
    },
    totalEn() {
      return function () {
        if (localStorage.getItem('lang') === 'en') {
          return 'padding-right: 90px;'
        }

        return 'padding-right: 64px;'
      }
    },

  },

  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('contractStore', ['loadContract']),
    ...mapActions('customerStore', ['loadCustomer']),
    ...mapMutations('app', ['setOverlayStatus']),
    loadData() {
      this.setOverlayStatus(true)
      Promise.all([
        this.loadContract(this.$route.params.id),

      ]).then(() => {
        console.log('contract:', this.selectedContract)
        this.customer = this.selectedContract.customer

        this.contract = this.$deepCopy(this.selectedContract.contract)
      }).finally(() => {
        this.setOverlayStatus(false)
      })
    },

    goBack() {

    },
  },
}
</script>

  <style scoped>
  #printArea {
    /* line-height: 1.5rem !important;
    width:794px;
    height:1090px; */
    height:1400px;
  }
  .contractTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }
  .purchased-items-table{
    background-color: transparent !important;
  }
  .purchased-items-table tbody tr {
    border: none;
  }
  .purchasedItems{
    width: 100%;
    border-collapse: collapse;
  }
  .purchasedItems tr th, .purchasedItems tr td {
    border: thin solid #414141;
    padding: 5px;
  }
  /* .purchasedItems tr th, .purchasedItems tr:last-child td {
    border-left: thin solid #414141;
    border-right: thin solid #414141;
    padding: 5px;
  } */
  /* .thead th{
    border-left: thin solid #414141;
    border-top: thin solid #414141;
  }
  .thead th:last-child{
    border-right: thin solid #414141;
  }
  .tbody td{
    border-top: thin solid #414141;
    border-left: thin solid #414141;
  }
  .tbody td:last-child{
    border-right: thin solid #414141;
  }
  .tfoot th{
    border: thin solid #414141;
  } */
  .div-boeder{
    border: thin solid #414141;
  }
  .under-line{
    border-bottom: 1px solid black;
  }

.table-bank{
  border: thin solid #414141;
  padding: 5px;
  width: 100%;
}
.table-bank td,.table-bank th {
  /* border: 1px solid #000; */
}

</style>
